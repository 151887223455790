<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <div class="col-md-12"  v-if="requisition.requisition_status.includes('ADMIN_PENDING') && !requisition.requisition_status.includes('ADMIN_APPROVED') && !requisition.requisition_status.includes('REQUISITION_REJECT')">
     <div class="col-md-6 pull-left">
       <p style="margin-bottom: 0px !important;">
         <span style="color: red">*</span> If you want APPROVE this requisition
         <a href="javascript:void(0);" @click.prevent="approveRequisition">click here</a>
       </p>
       OR
       <p style="margin-bottom: 5px !important;">
         <span style="color: red">* If you want REJECT this requisition </span>
         <a href="javascript:void(0);" @click.prevent="rejectRequisition">click here</a>
       </p>
     </div>
      <div class="col-md-6 pull-right">
        <p></p>
        <p class="pull-right" style="margin-bottom: -10px !important;">
          <span style="color: red">*</span> If you want modify/update with one time
          <router-link :to="{ name: 'adminHubRequisitionUpdate', params: { requisition_id: requisition.id } }" style="color: #42BAF9" tag="a">
            click here
          </router-link>
        </p>
      </div>
    </div>
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th>SL</th>
              <th>Product Name</th>
              <th>Product Unit</th>
              <th>W. Stock</th>
              <th>Hub Stock</th>
              <th>Status</th>
              <th>Requested</th>
              <th>Accepted</th>
              <th>Requested Comment</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition_item, index) in requisition.requisition_items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ requisition_item.product.name }}</td>
              <td>{{ requisition_item.product.unit }}</td>
              <td>{{ requisition_item.product.available_quantity }}</td>
              <td>{{ requisition_item.product.hub_product_stock ? requisition_item.product.hub_product_stock.stock_amount : 0 }}</td>
              <td>
                <span class="badge badge-warning" v-if="requisition_item.status === 'Pending'">Pending</span>
                <span class="badge badge-danger" v-if="requisition_item.status === 'Rejected'">Rejected</span>
                <span class="badge badge-success" v-if="requisition_item.status === 'Accepted'">Accepted</span>
                <span class="badge badge-warning" v-if="requisition_item.status === 'Partial'">Partial</span>
              </td>
              <td>{{ requisition_item.amount }}</td>
              <td>{{ requisition_item.accepted_amount }}</td>
              <td>{{ requisition_item.req_comment }}</td>
              <td>{{ requisition_item.comment }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
    </div>
    <full-accept
    :amount="amount"
    :requisitionItemId="requisitionItemId"
    :show="show"
    @getRequisition="getRequisition">
    </full-accept>
    <partial-accept
    :requisition_item="requisition_item"
    :show="show"
    @getRequisition="getRequisition">
    </partial-accept>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import fullAccept from '@/views/requisition/warehouse/modal/fullAccept'
import partialAccept from '@/views/requisition/warehouse/modal/partialAccept'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'admin_view',
  components: { fullAccept, partialAccept, requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      selectedIds: [],
      requisition: {},
      amount: '',
      requisitionItemId: '',
      requisition_item: {},
      approveBtn: false,
      loading: false,
      show: false,
      spinning: false,
      delayTime: 500,
      current: 0,
    }
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/warehouse/hub/requisition/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    fullAccept(amount, requisitionItemId, availableQuantity, $event, index) {
      const totalAvailableQuantity = availableQuantity
      const acceptAmount = amount
      if (parseFloat(acceptAmount) > parseFloat(totalAvailableQuantity)) {
        this.$notification.error({
          message: 'Accept quantity amount is crossing total available amount',
        })
        this.$bvModal.hide('requisition-full-accept-modal')
      } else {
        this.amount = ''
        this.requisitionItemId = ''
        this.amount = amount
        this.requisitionItemId = requisitionItemId
        this.$bvModal.show('requisition-full-accept-modal')
      }
    },
    partialAccept(requisitionItem) {
      this.requisition_item = {}
      this.requisition_item = requisitionItem
      this.$bvModal.show('requisition-partial-accept-modal')
    },
    onDelete(requisitionItemId) {
      if (confirm('Do you really want to reject?')) {
        apiClient.post('api/admin/hub/requisition/reject/', { requisitionItemId: requisitionItemId }).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRequisition()
            }
          } else {
            this.$notification.error({
              message: 'Reject Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    approveRequisition() {
      if (confirm('Do you really approve this requisition?')) {
        const requisitionId = this.$route.params.requisition_id
        this.selectedIds.push(requisitionId)
        this.approveBtn = true
        apiClient.post('api/admin/hub/requisition/all-approve', { requisitionIds: this.selectedIds })
          .then(response => {
            this.approveBtn = false
            if (!response.data.error) {
              this.$router.push({ name: 'adminHubPendingRequisitionHistory' })
              this.$notification.success({
                message: response.data.message,
              })
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    rejectRequisition() {
      if (confirm('Do you really reject this requisition?')) {
        const requisitionId = this.$route.params.requisition_id
        this.rejectBtn = true
        apiClient.post('api/admin/requisition-reject', { requisitionId: requisitionId })
          .then(response => {
            this.rejectBtn = false
            if (!response.data.error) {
              this.$router.push({ name: 'adminHubPendingRequisitionHistory' })
              this.$notification.success({
                message: response.data.message,
              })
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    resetForm() {
      this.requisition_item = {}
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
