<template>
  <div>
    <div class="mb-5">
      <b-modal id="requisition-partial-accept-modal" title="requisition Partial Accept" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded" v-if="Object.keys(requisition_item).length !== 0">
          <table class="table table-sm table-bordered">
            <tr>
              <th>Product</th>
              <td>{{ requisition_item.product.name }}</td>
            </tr>
            <tr>
              <th>Request Amount</th>
              <td>{{ requisition_item.amount }}</td>
            </tr>
          </table>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="available_quantity" class="col-form-label col-form-label-sm">Available Stock</label>
              <input type="text" v-model="requisition_item.product.available_quantity" id="available_quantity" name="available_quantity" class="form-control form-control-sm"  readonly disabled/>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="accepted_amount" class="col-form-label col-form-label-sm">Transfer Stock <span class="custom-required">*</span></label>
              <input type="number" v-model="requisition_item.partial_accepted_amount" v-validate="{ required: true, max: 5, regex: /^[0-9]*$/ }" @click.prevent="partialAcceptAmountCheck($event.target.value, requisition_item.product.available_quantity)" id="accepted_amount" name="accepted_amount" class="form-control form-control-sm" placeholder="Enter product quantity"/>
              <div class="invalid-feedback">Transfer stock is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <label for="comment" class="col-form-label col-form-label-sm">Comment</label>
              <textarea v-model="requisition_item.partial_comment" name="comment" id="comment" rows="2" class="form-control form-control-sm" placeholder="Enter comment.."></textarea>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="partialAccept">Accept</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('requisition-partial-accept-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'partialAccept',
  props: ['requisition_item', 'show'],
  data() {
    return {
      validation_errors: {},
      loader: false,
    }
  },
  mounted() { },
  methods: {
    partialAccept() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/warehouse/hub/requisition/partial-accept', this.requisition_item).then(response => {
            if (response.data.error === false) {
              this.loader = false
              this.$bvModal.hide('requisition-partial-accept-modal')
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('getRequisition')
            } else {
              this.loader = false
              this.$bvModal.hide('requisition-partial-accept-modal')
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    partialAcceptAmountCheck($event, availableQuantity) {
      const totalAvailableQuantity = availableQuantity
      const acceptAmount = $event
      if (parseFloat(acceptAmount) > parseFloat(totalAvailableQuantity)) {
        this.$notification.error({
          message: 'Partial quantity amount is crossing total available amount',
        })
        this.$bvModal.hide('requisition-partial-accept-modal')
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
